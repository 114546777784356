import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const FooterComponent = () => {
    const contactIcons = [
        {
            "url": "https://www.youtube.com/@AlimahInternational",
            icon: <FeatherIcon icon='youtube' size={24} />,
        },
        {
            "url": "https://www.linkedin.com/company/alimahinternational/?viewAsMember=true",
            icon: <FeatherIcon icon='linkedin' size={24} />,
        },
        {
            "url": "https://x.com/AlimahInter",
            icon: <FeatherIcon icon='twitter' size={24} />,
        },
    ];
    const quickLinks = [
        {
            "name": "Who We Are",
            "path": "/about",
        },
        {
            "name": "What We Do",
            "path": "/team",
        },
        {
            "name": "Resources",
            "path": "/resources",
        },
        {
            "name": "Contact Us",
            "path": "/contact",
        },
        {
            "name": "Careers",
            "path": "/home",
        },
        {
            "name": "FAQs",
            "path": "/faqs",
        },
    ];
    return (
        <motion.footer className='footer'
            initial={{ opacity: 0, transform: `translateY(240px)` }}
            whileInView={{ opacity: 1, transform: `translateY(0px)` }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >
            <div className='flex-auto'>
                <div className='col8'>
                    <div className='column-auto'>
                        <NavLink to={`/home`} className='footer-brand'>
                            <img loading="lazy" className="image-fluid contain" alt='Alimah International logo' src='./Assets/Images/Logo/Logo.png' />
                        </NavLink>
                        <div className='row'>
                            {
                                contactIcons.map((contact, i) =>
                                    <a href={contact.url} target='_blank' className='icon-button' key={i} rel="noreferrer">{contact.icon}</a>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='col4'>
                    <div className='column16 col12'>
                        <h1 className='footer-title white'>Quick Links</h1>
                        <div className='grid3x2'>
                            {
                                quickLinks.map((val, i) =>
                                    <NavLink to={val.path} className='footer-link' key={i}>
                                        {val.name}
                                    </NavLink>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-divider' />
            <div className='stretch'>
                <p className='primary-text white right'>Copyright 2024. All rights reserved.</p>
            </div>
        </motion.footer>
    );
}

export default FooterComponent;