import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import FaqPage from './Pages/faq';
import HomePage from './Pages/home';
import TeamPage from './Pages/team';
import ErrorPage from './Pages/error';
import AboutPage from './Pages/about';
import ContactPage from './Pages/contact';
import ExchangesPage from './Pages/exchanges';
import ResourcesPage from './Pages/resources';
import MentorshipPage from './Pages/mentorship';
import AboutResourcesPage from './Pages/resources/about';
import ExchangesRecordPage from './Pages/exchanges/history';

const router = createHashRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/home",
    element: <HomePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/about",
    element: <AboutPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/mentorship",
    element: <MentorshipPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/team",
    element: <TeamPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/exchanges",
    element: <ExchangesPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/faqs",
    element: <FaqPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/resources",
    element: <ResourcesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/resources/:resourcesId",
    element: <AboutResourcesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/exchange_history",
    element: <ExchangesRecordPage />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);