import React from 'react';
import TransitionComponent from '../Components/transition.component';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';
import { motion } from "framer-motion";

export default function ContactPage() {
    const formRef = React.useRef();

    const handleSubmit = (ev) => {
        ev.preventDefault();
        console.log(formRef.current.value);
        return false;
    }

    return (
        <TransitionComponent>
            <HeaderComponent />

            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className="column32 stretch fill">
                    <div className='column16'>
                        <h6 className="subtitle-text white">Contact Us</h6>
                        <h1 className="title-text white">Get In Touch With US</h1>
                        <div className='divider white' />
                    </div>
                    <form ref={formRef} className="box" onSubmit={handleSubmit}>
                        <div className='column32 stretch fill'>
                            {/* Name */}
                            <div className='column8 stretch'>
                                <label className='primary-text'>
                                    <strong>Name:</strong>
                                </label>
                                <input type='text' className='form-control' name='name' placeholder='Enter your name...' maxLength={256} required />
                            </div>
                            {/* Email Address */}
                            <div className='column8 stretch'>
                                <label className='primary-text'>
                                    <strong>Email Address:</strong>
                                </label>
                                <input type='email' className='form-control' name='email' placeholder='Enter your email address...' maxLength={256} required />
                            </div>
                            {/* Message */}
                            <div className='column8 stretch'>
                                <label className='primary-text'>
                                    <strong>Message:</strong>
                                </label>
                                <textarea type='text' className='form-control' name='message' placeholder='Enter your message...' maxLength={5000} required />
                            </div>
                            {/* Submit */}
                            <div className='column8'>
                                <input type='submit' value='Submit' className='primary-button' />
                            </div>
                        </div>
                    </form>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent>
    )
}
